import React from 'react';
import us from '../../img/us-flag.svg'
import jp from '../../img/jp-flag.svg'
import styled from "styled-components";

const LanguageSwitcher = () => {
    return (
        <Wrap>
            <img src={jp} alt="JP"/>
            <Dropdown>
                <DropdownInner>
                    <DropdownItem href="https://www.tomorrow.io/">
                        <img width="100%" src={us} alt="US"/>
                    </DropdownItem>
                    <DropdownItem href="http://www.gettomorrow.jp/" active>
                        <img width="100%" src={jp} alt="JP"/>
                    </DropdownItem>
                </DropdownInner>
            </Dropdown>
        </Wrap>
    );
};

export default LanguageSwitcher;


const Wrap = styled.div`
  position: relative;
  cursor: default;
  margin-left: 1rem;
  line-height: 1;
  width: 36px;
  img {
    width: 100%;
    border: 1px solid rgba(0,0,0,.05);
  }
  &:hover {
    > div {
      display: block;
    }
  }
`;

const Dropdown = styled.div`
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const DropdownInner = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
  padding: .5rem;
  position: relative;
  top: 8px;
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background: white;
  }
`;

const DropdownItem = styled.a`
    display: block;
    opacity: ${props => props.active ? .7 : 1};
    width: 24px;
    cursor: ${props => props.active ? 'default' : 'pointer'};
    pointer-events: ${props => props.active ? 'none' : 'auto'};
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
    img {
      border: 1px solid rgba(0,0,0,.05);
    }
`;