import React from "react";
import { Link } from "gatsby";
import { Container, Box } from "../styles/Styles";
import theme from "../styles/theme";
import styled from "styled-components";
import twitter from "../img/icon-twitter-black.svg";
import facebook from "../img/icon-facebook-black.svg";
import linkedin from "../img/icon-linkedin-black.svg";
import inst from "../img/icon-inst-black.svg";
import hiringLabel from "../img/label-hiring.png";

const products = {
  name: "プロダクト",
  submenu: [
    {
      name: "tomorrow.io Platform",
      link: "/software/",
      id: "link-software"
    },
    {
      name: "気象予報API",
      link: "/weather-api/",
      id: "link-weather-api"
    },
    {
      name: "WAI：AIのための過去の天気",
      link: "/historical-weather-data/",
      id: "link-historical-weather-dat"
    },
  ]
};

const company = {
  name: "会社",
  submenu: [
    { name: "概要", link: "/company/", id: "link-company" },
    {
      name: "カスタマーストーリー",
      link: "/customer-stories/",
      id: "link-customer-stories"
    },
  ]
};

export default ({ page }) => {
  return (
    <Wrap dark={page === "hypercast"}>
      <Container>
        <Box display="flex" flexWrap="wrap" mb={5}>
          <Box mb={4} width={[1, 1 / 4]}>
            <Box mb={2} fontWeight={700}>
              Products
            </Box>
            {products.submenu.map((item, i) => (
              <Box mb={2} key={i}>
                {item.label && <Box color={theme.color.mute}>{item.label}</Box>}
                {item.link && (
                  <Box as={Link} to={item.link} id={`footer-${item.id}`}>
                    {item.name}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          <Box mb={4} width={[1, 1 / 4]}>
            <Box mb={2} fontWeight={700}>
              Company
            </Box>
            {company.submenu.map((item, i) => (
              <Box mb={2} key={i}>
                {item.link === "/careers/" ? (
                  <HiringLink key={i} to={item.link} id={`footer-${item.id}`}>
                    {item.name}
                  </HiringLink>
                ) : (
                  <Link key={i} to={item.link} id={`footer-${item.id}`}>
                    {item.name}
                  </Link>
                )}
              </Box>
            ))}
          </Box>
          <Box mb={4} width={[1, 1 / 2]}>
            <Box mb={3}>
              <strong>問い合わせ</strong>
              <br/>
              <a href="tel:+18007357075">+1 (800) 735-7075</a>,{" "}
              <a href="mailto:sales@tomorrow.io">sales@tomorrow.io</a>
            </Box>
            <Box mb={3}>
              <strong>tomorrow.io HQ:</strong>
              <br/>
              25 Dorchester Ave, Rm 52150, Boston, MA 02205
            </Box>
            <Box mb={3}>
              <strong>tomorrow.io TLV:</strong>
              <br/>
              Platinum Tower, 21 Ha'Arba'a St., 20th floor, Tel Aviv, 6473921
            </Box>
            <Box mb={3}>
              <strong>tomorrow.io Boulder:</strong>
              <br/>
              5541 Central Avenue West, Suite 270, Boulder, CO&nbsp;80301
            </Box>
            <Box>
              <strong>tomorrow.io Asia Pacific:</strong>
              <br/>
              83 Clemenceau Avenue, 2nd Floor, Singapore, 239920
            </Box>
          </Box>
        </Box>
        <Box mb={5} textAlign="center">
          <Box
            as="a"
            mx={3}
            href="https://twitter.com/climacell"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img height={30} src={twitter} alt=""/>
          </Box>{" "}
          <Box
            as="a"
            mx={3}
            href="https://www.facebook.com/microweather"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img height={30} src={facebook} alt=""/>
          </Box>{" "}
          <Box
            as="a"
            mx={3}
            href="https://www.linkedin.com/company/climacell/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img height={30} src={linkedin} alt=""/>
          </Box>{" "}
          <Box
            as="a"
            mx={3}
            href="https://www.instagram.com/climacell/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img height={30} src={inst} alt=""/>
          </Box>{" "}
        </Box>

        <Box mb={4} textAlign="center">
          <Box as="a" fontWeight={800} href="https://www.tomorrow.io/legal/">
            リーガル
          </Box>
          <Box mb={5}>
            Copyright &copy; {new Date().getFullYear()} tomorrow.io, Inc. All
            rights reserved
          </Box>
        </Box>
      </Container>
    </Wrap>
  );
};

const Wrap = styled.footer`
  background-color: ${props => (props.dark ? theme.color.secondary : "")};
  color: ${props => (props.dark ? "white" : "")};
  border-top: 1px solid ${props =>
  props.dark ? "rgba(255,255,255,.1)" : "rgba(0,0,0,.1)"};
  font-size: 1rem;
  padding-top: 3rem;
  position: relative;
  img {
    filter:  ${props => (props.dark ? "invert(1)" : "")};
  }
  a {
    color: ${props => (props.dark ? "white" : "black")};
    transition: color .2s;
    &:hover {
      color: ${theme.color.primary};
    }
  }
}
`;

const HiringLink = styled(Link)`
  display: inline-block;
  position: relative;
  &:before {
    content: "";
    width: 105px;
    height: 41px;
    position: absolute;
    top: -10px;
    right: -110px;
    background: url(${hiringLabel}) no-repeat center/ contain;
  }
`;
