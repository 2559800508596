import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({ description, title, image, pathname }) {
  return (
    <StaticQuery
      query={seoQuery}
      render={data => {
        const metaTitle = title || data.site.siteMetadata.title;
        const metaDescription =
          description || data.site.siteMetadata.description;
        const ogImage = image || "https://www.tomorrow.io/og_image.png";
        const ogUrl = `https://www.tomorrow.io${pathname}`;

        return (
          <Helmet>
            <html lang="ja" />
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />

            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#5bbad5"
            />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />

            <meta property="og:title" content={metaTitle} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:type" content="website" />
            {pathname ? <meta property="og:url" content={ogUrl} /> : ""}
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:image" content={ogImage} />
            <meta name="twitter:description" content={metaDescription} />
          </Helmet>
        );
      }}
    />
  );
}

export default SEO;

const seoQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
