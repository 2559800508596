import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import logo from "../../img/tomorrow-logo-colored.svg";
import logoWhite from "../../img/tomorrow-logo-white.svg";

const Logo = ({ page }) => {
  return (
    <Wrap to="/" title="Logo">
      <img src={page === "hypercast" ? logoWhite : logo} alt="tomorrow.io" />
    </Wrap>
  );
};

export default Logo;

const Wrap = styled(Link)`
  margin-right: 1rem;
  @media screen and (min-width: 600px) {
    margin-right: 2.5rem;
  }
  img {
    width: 120px;
    @media screen and (min-width: 600px) {
      width: 130px;
      margin-bottom: 3px;
    }
  }
`;
