import React from "react";
import styled from "styled-components";
import MenuDropdown from "./MenuDropdown";
import { Link } from "gatsby";
import { Box } from "../../styles/Styles";

const MenuItem = ({ section, page }) => {
  return (
    <>
      {!section.link && section.name && (
        <Wrap>
          <Box
            fontWeight={800}
            color={page === "hypercast" ? "white" : "black"}
          >
            {section.name}
          </Box>
          {section.submenu && <MenuDropdown submenu={section.submenu} />}
        </Wrap>
      )}
      {section.link && (
        <Wrap>
          <Box
            as={Link}
            fontWeight={800}
            to={section.link}
            id={`header-${section.id}`}
          >
            <Box color={page === "hypercast" ? "white" : "black"}>
              {section.name}
            </Box>
          </Box>
        </Wrap>
      )}
    </>
  );
};

export default MenuItem;

const Wrap = styled.div`
  position: relative;
  cursor: default;
  margin-right: 1.1rem;
  &:hover {
    > div {
      display: block;
    }
  }
`;
