import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Box } from "../../styles/Styles";

const MenuDropdown = ({ submenu }) => {
  return (
    <Wrap>
      {submenu.map((section, i) => (
        <Item color={section.label} key={i}>
          {section.label && <Box as={Item} py=".25rem">{section.label}</Box>}
          {section.link && (
            <Box
              as={Link}
              pl={section.small && 2}
              fontSize={section.small && 2}
              to={section.link}
              id={`header-${section.id}`}
            >
              {section.name}
            </Box>
          )}
          {section.alink && (
            <a href={section.alink} id={`header-${section.id}`}>
              {section.name}
            </a>
          )}
        </Item>
      ))}
    </Wrap>
  );
};

export default MenuDropdown;

const Wrap = styled.div`
  display: none;
  width: 210px;
  position: absolute;
  padding: 0.75rem 1rem;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
`;

const Item = styled.div`
  a {
    display: block;
    color: black;
    transition: opacity 0.3s;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    &:hover {
      color: black;
      opacity: 0.6;
    }
  }
`;
