import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer";
import "../../scss/all.scss";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../styles/theme";
import SEO from "../Seo";

const Layout = ({ children, page, bg, noHeader, noFooter, noBanner }) => {
  return (
    <Wrap bg={bg}>
      <ThemeProvider theme={theme}>
        <SEO/>

        {/*{!noBanner && <Banner href="/blog/porsche-to-predict-upcoming-weather-conditions-for-drivers/">
          Tomorrow.io partners with Porsche to predict road conditions, see the story here!
        </Banner>
        }*/}
        {!noHeader && <Header page={page}/>}
        <Main>{children}</Main>
        {!noFooter && <Footer page={page}/>}
      </ThemeProvider>
    </Wrap>
  );
};

export default Layout;

const Wrap = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.bg};
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: block;
`;


/*
const Banner = styled.a`
  position: relative;
  z-index: 1020;
  background-color: #e0eeff;
  font-weight: 800;
  font-size: .85rem;
  text-align: center;
  padding: .2rem .5rem;
  transition: .2s;
  color: black;
  display: block;
  :hover {
    color: #444;
  }
`;
*/
