import React from "react";
import styled from "styled-components";
import MenuDropdown from "./MenuDropdown";
import { Box } from "../../styles/Styles";

const data = {
  name: "ログイン",
  submenu: [
    {
      name: "tomorrow.io™",
      alink: "https://app.tomorrow.io/signin",
      id: "link-hypercast-login"
    },
    {
      name: "気象API",
      alink: "https://developer.tomorrow.io/signin",
      id: "link-developer-login"
    }
  ]
};

const LoginItem = ({ page, top }) => {
  const linkColor =
    (page === "home" && top) || page === "hypercast" || page === "aq" ? "white" : "black";
  return (
    <Wrap>
      <Box fontWeight={800} color={linkColor}>
        {data.name}
      </Box>
      <MenuDropdown submenu={data.submenu}/>
    </Wrap>
  );
};

export default LoginItem;

const Wrap = styled.div`
  display: none;
  @media screen and (min-width: 990px) {
    display: block;
    position: relative;
    cursor: default;
    margin-right: 1rem;
    white-space: nowrap;
    &:hover {
      > div {
        display: block;
      }
    }
  }
`;
