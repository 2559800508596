export const sections = [
  {
    name: "Platform",
    link: "/software/",
    id: "link-software"
  },
  {
    name: "気象API",
    link: "/weather-api/",
    id: "link-weather-api"
  },
  {
    name: "過去データ",
    link: "/historical-weather-data/",
    id: "link-historical-weather-dat"
  },
  {
    name: "大気質",
    link: "/air-quality/",
    id: "link-air-quality"
  },
  {
    name: "産業",
    submenu: [
      {
        name: "航空",
        link: "/industry/aviation/",
        slug: "aviation",
        id: "link-aviation",
        info:
          "A brand-new source of weather data for airline and airport operations"
      },
      {
        name: "建設",
        link: "/industry/construction/",
        slug: "construction",
        id: "link-construction",
        info: "Keep your outdoor operations far ahead of the weather"
      },
      {
        name: "エネルギー",
        link: "/industry/energy/",
        slug: "energy",
        id: "link-utilities",
        info: "Microweather conditions across the grid"
      },
      {
        name: "オンデマンド経済",
        link: "/industry/demand/",
        slug: "demand",
        id: "link-on-demand-economy",
        info:
          "Is it raining on your customer's street? Only Tomorrow.io™ can tell you"
      },
      {
        name: "アウトドア",
        link: "/industry/outdoor/",
        slug: "outdoor",
        id: "link-outdoor-entertainment",
        info:
          "A brand-new source of weather data for sports, festivals, and theme parks"
      },
      {
        name: "交通",
        link: "/industry/transportation/",
        slug: "transportation-and-logistics",
        id: "link-transportation-and-logistics",
        info: "Weather hazard on route? Only Tomorrow.io™ can tell you"
      },
      {
        name: "ドローン",
        link: "/industry/drones/",
        slug: "drones",
        id: "link-unmanned-aerial-systems",
        info: "Low-altitude weather conditions for safe drone operations"
      }
    ]
  },
  {
    name: "テクノロジー",
    submenu: [
      { label: "プロダクト:" },
      {
        name: "tomorrow.io Platform",
        link: "/software/",
        id: "link-software",
        small: true
      },
      {
        name: "気象予報API",
        link: "/weather-api/",
        id: "link-weather-api",
        small: true
      },
      {
        name: "WAI",
        link: "/historical-weather-data/",
        id: "link-historical-weather-dat",
        small: true
      },
      { label: "弊社のモデル:" },
      {
        name: "CBAM",
        link: "/cbam/",
        id: "link-cbam",
        small: true
      },
      {
        name: "道路リスク",
        link: "/road-risk-scores/",
        id: "link-road",
        small: true
      },
      {
        name: "洪水リスク",
        link: "/urban-flooding-forecast/",
        id: "link-urban-flooding-forecast",
        small: true
      },
      {
        name: "大気質",
        link: "/air-quality/",
        id: "link-air-quality",
        small: true
      },
      {
        name: "データ",
        link: "/model/",
        id: "link-data"
      },
      {
        name: "チーム",
        link: "/science-team/",
        id: "link-science-team"
      }
    ]
  },
  {
    name: "会社概要",
    submenu: [
      { name: "会社", link: "/company/", id: "link-company" },
      {
        name: "カスタマーストーリー",
        link: "/customer-stories/",
        id: "link-customer-stories"
      },
    ]
  }


];
