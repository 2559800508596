const theme = {
  color: {
    primary: "#0072F5",
    secondary: "#2A2A45",
    mute: "#6c757d",
    light: "#f6f6f6",
    yellow: "#ffd02f",
    fresh: "#03CF85",
  },
  fontSizes: [0, 12, 14, 16, 18, 24, 36, 48, 80, 96],
  breakpoints: ["768px", "1024px"],
};

export default theme;
