import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../../styles/Styles";
import { Box, Button } from "../../styles/Styles";
import Logo from "./Logo";
import Menu from "./Menu";
import LoginItem from "./LoginItem";
import MenuMobile from "./MenuMobile";
import theme from "../../styles/theme";
import LanguageSwitcher from "./LanguageSwitcher";

export default ({ page }) => {
  const [top, setTop] = useState(true);
  const bg =
    page === "hypercast" 
      ? theme.color.secondary
      : top
      ? "transparent"
      : "white";

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 50) {
        setTop(false);
      } else {
        setTop(true);
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  return (
    <Wrap bg={bg}>
      <Container>
        <Box display="flex" alignItems="center">
          <Logo page={page} />
          <Menu page={page} />
          <LoginItem page={page} top={top} />
          <Button
              fontSize={['.6rem', '.8rem']}
            as="a"
            small="true"
            ml="auto"
            href={page === "api" ? "https://Tomorrow.io.co/api-signup/" : "https://Tomorrow.io.co/free-trial?bid=2"}
            id="header-form-link"
          >
            {page === "api" ? "無料トライアルを試す" : "デモを予約"}
          </Button>
          <LanguageSwitcher/>
          <MenuMobile />
        </Box>
      </Container>
    </Wrap>
  );
};

const Wrap = styled.header`
  position: sticky;
  top: 0;
  z-index: 990;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: ${props => (props.bg ? props.bg : `transparent`)};
`;
