import React, { useState } from "react";
import { Link } from "gatsby";
import { sections } from "./menuData";
import styled from "styled-components";
import { Menu, X } from "react-feather";
import { Box } from "../../styles/Styles";

const MenuMobile = () => {
  const [show, setShow] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(null);

  return (
    <Wrap>
      <Burger onClick={() => setShow(!show)}>{show ? <X/> : <Menu/>}</Burger>
      {show && (
        <Nav>
          {sections.map((section, i) => (
            <React.Fragment key={i}>
              {!section.link && section.name ?
                <Item>
                  <button
                    onClick={() => setShowSubmenu(showSubmenu === i ? null : i)}
                  >
                    {section.name} <Arrow open={showSubmenu === i}/>
                  </button>
                  {showSubmenu === i && <SubmenuMobile submenu={section.submenu}/>}
                </Item>
                :
                <Item>
                  <Link to={section.link} id={section.id}>
                    {section.name}
                  </Link>
                </Item>
              }
            </React.Fragment>
          ))}

        </Nav>
      )}
    </Wrap>
  );
};

export default MenuMobile;

const SubmenuMobile = ({ submenu }) => (
  <Box pl={4}>
    {submenu.map((section, i) => (
      <Item nested small={section.small} key={i}>
        {section.label && (
          <Box py={2} fontSize="1.1rem">
            {section.label}
          </Box>
        )}
        {section.link && (
          <Link
            to={section.link}
            id={section.id}
          >
            {section.name}
          </Link>
        )}
        {section.alink && (
          <a href={section.alink} id={section.id}>
            {section.name}
          </a>
        )}
      </Item>
    ))}
  </Box>
);

const Wrap = styled.div`
  @media screen and (min-width: 990px) {
    display: none;
  }
`;

const Nav = styled.nav`
  position: fixed;
  z-index: -10;
  width: 100%;
  top: 55px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  padding: 10vh 10vw;
  overflow: auto;
  background-color: white;
`;

const Item = styled.div`
  padding-left: ${props => (props.small ? "1rem" : "0")};
  button,
  a {
    display: inline-flex;
    align-items: center;
    font-size: ${props => (!props.nested ? "1.5rem" : props.small ? ".95rem" : "1.1rem")};
    padding: 0.5rem 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: black;
    outline: none !important;
  }
`;

const Burger = styled.button`
  margin-left: 0.5rem;
  background-color: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  svg {
    width: 30px;
    height: 30px;
    color: #a5a5a5;
  }
`;

const Arrow = styled.span`
  display: inline-block;
  margin-top: 5px;
  width: 0;
  height: 0;
  margin-left: 10px;
  border-style: solid;
  border-width: 5px 0 5px 6px;
  transform: ${props => (props.open ? "rotate(270deg)" : "rotate(90deg)")};
  border-color: transparent transparent transparent #000;
  transition: transform 0.15s ease;
  will-change: transform;
`;
