import styled from "styled-components";
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  typography
} from "styled-system";
import theme from "./theme";

export const boxExtra = [
  space,
  layout,
  color,
  typography,
  shadow,
  flexbox,
  background,
  border,
  position,
  shadow
];
const extraText = [color, space, layout, typography, shadow, border];

export const Box = styled.div`
  ${boxExtra}
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  ${boxExtra}
`;

export const Section = styled.section`
  position: relative;
  overflow: hidden;
  ${boxExtra}
`;

export const List = styled.ul`
  list-style: none;
  padding-left: 0.5rem;
  li {
    margin-bottom: 1rem;
    &:before {
      content: "•";
      font-size: 120%;
      line-height: inherit;
      display: inline-block;
      color: ${theme.color.primary};
      width: 0.7em;
      margin-right: 0.25rem;
    }
  }
  ${boxExtra}
`;

export const Button = styled.button`
  display: inline-block;
  text-align: center;
  background-color: ${props =>
  props.outline ? "transparent" : props.bg ? props.bg : theme.color.primary};
  color: ${props => (props.outline ? theme.color.primary : "white")};
  padding: ${props => (props.small ? `.3rem .5rem` : `.5rem 1.1rem`)};
  font-size: ${props => (props.small ? `.85rem` : `1.3rem`)};
  line-height: 1.4;
  border: 1px solid
    ${props => (props.outline ? theme.color.primary : theme.color.light)};
  border-radius: 5px;
  white-space: nowrap;
  letter-spacing: 0.25px;
  cursor: pointer;
  transition: filter 0.2s;
  :hover {
    filter: brightness(95%);
    color: ${props => (props.outline ? theme.color.primary : "white")};
  }
  :focus {
    outline: none;
  }
  ${boxExtra}
`;

export const ButtonFresh = styled(Button)`
  background-color: ${props => props.outline ? "transparent" : props.bg ? props.bg : theme.color.fresh};
  color: ${props => (props.outline ? theme.color.fresh : "white")};
  border: 1px solid ${props => (props.outline ? theme.color.fresh : theme.color.light)};
  :hover {
    color: ${props => (props.outline ? theme.color.fresh : "white")};
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  ${boxExtra}
`;

export const T1 = styled.h1`
  font-size: 2.2rem;
  line-height: 1.1;
  @media only screen and (min-width: 992px) {
    font-size: 4rem;
  }
  ${extraText}
`;

export const T2 = styled.h2`
  font-size: 1.8rem;
  @media only screen and (min-width: 992px) {
    font-size: 2.6rem;
  }
  ${extraText}
`;

export const T3 = styled.h3`
  font-size: 1.6rem;
  @media only screen and (min-width: 992px) {
    font-size: 2rem;
  }
  ${extraText}
`;

export const T4 = styled.h4`
  font-size: 1.4rem;
  @media only screen and (min-width: 992px) {
    font-size: 1.75rem;
  }
  ${extraText}
`;

export const T5 = styled.h5`
  font-size: 1.25rem;
  @media only screen and (min-width: 992px) {
    font-size: 1.4rem;
  }
  ${extraText}
`;

export const Lead = styled.p`
  font-size: 1.1rem;
  @media only screen and (min-width: 992px) {
    font-size: 1.25rem;
  }
  ${extraText}
`;

export const Color = styled.span`
  color: ${props => props.color ? props.color : "inherit"};
`;

export const Input = styled.input`
  padding: .4rem .75rem;
  width: 100%;
  outline: none;
  border-radius: 6px;
  box-shadow: none;
  border: 1px solid rgba(0,0,0,.1);
  font-size: 1rem;
  transition: all 0.2s;
  color: black;
  background-color: white;
  &:focus {
    outline: none;
    border: 1px solid #9bcaff;
  }
`;

export const CardShadow = styled.div`
  border: 1px solid #EBEBEB;
  background-color: white;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 80%;
    z-index: -1;
    box-shadow: 0 2px 25px rgba(0,0,0,.25);
    border-radius: 8px;
  }
  ${boxExtra}
`;

export const PostBody = styled.div`
  font-size: 1.15rem;
  letter-spacing: 0.2px;

  h1,
  h2,
  h3,
  h4 {
    margin-top: 1.5rem;
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  ul {
    padding-left: 20px;
  }

  figure,
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  figure {
    margin-left: unset;
  }

  .wp-caption-text {
    color: #999;
    font-size: 80%;
    text-align: center;
  }

  iframe {
    max-width: 100%;
    @include media-breakpoint-down(sm) {
      height: auto;
      min-height: 200px;
    }
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .alignright {
    display: block;
    @media only screen and (min-width: 1024px) {
      float: right;
      margin-left: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .alignleft {
    display: block;
    @media only screen and (min-width: 1024px) {
      float: left;
      margin-right: 3rem;
    }
  }

  table {
    @extend .table, .table-striped;
  }

  pre {
    background-color: #fafafa;
    padding: 1rem;
    display: block;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-x: auto;
    code {
      color: black;
      font-size: 0.9rem;
    }
  }
`