import React from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import { sections } from "./menuData";

export default ({ page }) => {
  return (
    <Wrap>
      {sections.map((section, index) => (
        <MenuItem section={section} key={index} page={page} />
      ))}
    </Wrap>
  );
};

const Wrap = styled.nav`
  display: none;
  @media screen and (min-width: 990px) {
    width: 100%;
    align-items: center;
    display: flex;
  }
`;
